export const mixinAccordion = {
  data: () => ({
    isOpenAccordion: false
  }),
  methods: {
    collapseAccordion () {
      this.isOpenAccordion = !this.isOpenAccordion
    },

    closeAccordion () {
      this.isOpenAccordion = false
    }
  }
}
