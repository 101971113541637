<template>
  <div class="date-status">
    <div class="date-status__text" v-if="!inConsTable">{{ text }}</div>
    <div class="date-status__date" :class="getStatusClass">
      {{ dateFinished | date('date') }}
    </div>
  </div>
</template>

<script>
import { EOrderDateStatus } from '@/utils/order/order'
import { mixinOrderDateStatus } from '@/mixins/views/Production/Order/mixinOrderDateStatus'
export default {
  name: 'OrderDateStatus',
  props: {
    order: Object,
    inConsTable: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixinOrderDateStatus],
  computed: {
    text () {
      switch (this.statusType) {
        case EOrderDateStatus.fail: return 'Просрочен'
        case EOrderDateStatus.warning: return 'Срок подходит'
        default: return ''
      }
    },

    getStatusClass () {
      return 'date-status__date--' + this.statusType + (this.inConsTable ? ' in_cons_table' : '')
    }
  }
}
</script>

<style lang="scss" scoped>
.date-status {
  &__text {
    color: var(--color__gray-blue);
    @include font12;
  }

  &__date {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @include font14;

    &:after {
      content: '';
      display: block;
      @include size(12px);
      background: currentColor;
      border-radius: 50%;
      margin-left: 6px;
      transform: translateY(-1px);
    }

    &.in_cons_table {
      @include font16;
      &:after {
        display: none;
      }
    }

    &--fail {
      color: $color__red;
    }

    &--warning {
      color: $color__orange;
    }

    &--normal {
      color: $color__green;
    }
  }
}
</style>
