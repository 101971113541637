import { EOrderDateStatus } from '@/utils/order/order'

// В компонент должне быть передан объект заказа
const mixinOrderDateStatus = {
  computed: {
    statusType () {
      switch (this.order.ready_status) {
        case 2: return EOrderDateStatus.warning
        case 3: return EOrderDateStatus.fail
        default: return EOrderDateStatus.normal
      }
    },

    dateFinished () {
      return this.order.date_finished
    },

    isStatusFail () {
      return this.statusType === 'fail'
    },

    isStatusWarning () {
      return this.statusType === 'warning'
    }
  }
}

export {
  mixinOrderDateStatus
}
